// Packages
import React, { useState, useEffect } from "react";

// Services
import * as AssetApi from "apis/AssetApi";

import { useStyles } from "./styles";

// MUI
import DownloadIcon from "@material-ui/icons/GetApp";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import RefreshIcon from '@material-ui/icons/Refresh';
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";

export default function ZipStatus(props) {

    const status = {
      AVAILABLE: "Available",
      PROCESSING: "Processing",
      FAILED: "Failed"
    };
    const classes = useStyles();
    const [manifestId, setManifestId] = useState(props.manifestId);
    const [resetTimer, setResetTimer] = useState(false);
    const [zipStatus, setZipStatus] = useState(status.PROCESSING);
    const [downloadTime, setDownloadTime] = useState();

    useEffect(() => {
        getZipStatus(manifestId);
      }, [props.manifestId]);

      useEffect(() => {
        async function reCheckFileStatus() {
            await getZipStatus(manifestId);
          }

        const timeoutId = setTimeout(() => {
            reCheckFileStatus();
        }, 500);
        return () => {
            if(zipStatus === status.PROCESSING){
                setResetTimer(!resetTimer);
            }
            clearTimeout(timeoutId);
        };
      }, [resetTimer, 500]);

    async function getZipStatus(maifestId){
        const currentTime = new Date();
        const manifestTime = new Date(props.manifest.updated.date);

        await AssetApi.checkZipFileV1(maifestId).then( (data) => {
            if(String(data) === 'true'){
                setZipStatus(status.AVAILABLE);
            }
            else if( (currentTime.getTime() - (60000 * 15) <  manifestTime.getTime()) || (downloadTime && currentTime.getTime() - (60000 * 15) <  downloadTime.getTime() ) ){
                setZipStatus(status.PROCESSING);
            }
            else{
                setZipStatus(status.FAILED);
            }
        });
    
        return null;
    }

    return (
        <React.Fragment>
            <ListItemText
              primary={props.dateTimeFormat.format(new Date(props.manifest.created))}
              secondary={
                <React.Fragment>
                  <Typography>
                    {props.getSubheader(props.manifest.request)}
                  </Typography>
                  <Typography>
                    {props.getJurisdictions(props.manifest.request.states)}
                  </Typography>
                  <Typography>
                    {  zipStatus === status.AVAILABLE &&
                        'File: Available'
                    }
                    {  zipStatus === status.PROCESSING && 
                        'File: Processing'
                    }
                    {  zipStatus === status.FAILED &&
                        "File: Failed"//"File: Not available"
                    }
                  </Typography>
                </React.Fragment>
              }
            />
            <ListItemSecondaryAction>
              <IconButton
                onClick={() => {
                  setDownloadTime(new Date());
                  setZipStatus(status.PROCESSING);
                  setResetTimer(!resetTimer);
                  props.setManifestId(props.manifestId);
                  props.trackDownload(props.manifestId);
                  
                }}
                aria-label="download"
                disabled={zipStatus === status.PROCESSING}
              >
                { zipStatus !== status.FAILED && <DownloadIcon /> }
                { zipStatus === status.FAILED && <RefreshIcon />}
              </IconButton>
            </ListItemSecondaryAction>
        </React.Fragment>
  );
}